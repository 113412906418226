import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

interface IProps {
  returnFormInstance: UseFormReturn<{ exchangeRate: number; profit: number }>;
  onSubmit: (data: { exchangeRate: number; profit: number }) => void;
  initialValues?: { exchangeRate: number; profit: number }; // Добавляем пропс для начальных значений
}

const UpdateCurrencyPairModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
  initialValues, // Принимаем начальные значения
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset, // Используем метод reset для установки начальных значений
  } = returnFormInstance;

  // Устанавливаем начальные значения при изменении initialValues
  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        {/* Поле для ввода обменного курса */}
        <Controller
          name="exchangeRate"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="exchangeRate" className="form__label">
                Обменный курс
              </label>
              <input
                id="exchangeRate"
                className="form-control"
                type="number"
                step="0.00000001"
                placeholder="Введите курс обмена"
                value={value}
                onChange={onChange}
              />
              {errors.exchangeRate?.message && (
                <p className="error">{errors.exchangeRate?.message}</p>
              )}
            </div>
          )}
        />

        {/* Поле для ввода профита */}
        <Controller
          name="profit"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="profit" className="form__label">
                Профит, %
              </label>
              <input
                id="profit"
                className="form-control"
                type="number"
                step="0.01"
                placeholder="Введите профит от данного курса"
                value={value}
                onChange={onChange}
              />
              {errors.profit?.message && (
                <p className="error">{errors.profit?.message}</p>
              )}
            </div>
          )}
        />

        {/* Кнопка отправки формы */}
        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Обновить
        </button>
      </form>
    </div>
  );
};

export default UpdateCurrencyPairModal;
