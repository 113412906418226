import React from "react";

interface Country {
  id: string;
  country: string;
}

interface CountriesListProps {
  countries: Country[];
  onDelete: (id: string) => void;
}

const CountriesList: React.FC<CountriesListProps> = ({
  countries,
  onDelete,
}) => {
  return (
    <div className="exchange__table table-exchange table">
      <div className="table-exchange__items table__items table__items--head">
        <div className="table__item">ID</div>
        <div className="table__item">Название</div>
        <div className="table__item"></div>
        <div className="table__item"></div>
        <div className="table__item"></div>
      </div>
      {countries.map((country) => (
        <div key={country.id} className="table-exchange__items table__items">
          <div className="table__item">{country.id}</div>
          <div className="table__item">{country.country}</div>
          <div className="table__item"></div>
          <div className="table__item"></div>
          <div className="table__item">
            <div className="table__item-action">
              <button
                onClick={() => onDelete(country.id)}
                className="table__item-img"
              >
                <img
                  className="table__item-icon"
                  src="/img/svg/del.svg"
                  alt="Удалить"
                />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CountriesList;
