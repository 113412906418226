import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import {
  useGetDefaultRefererFee,
  useGetDefaultTraderFee,
  useSetFees,
} from "../../services/fees.services";
import toast from "react-hot-toast";

// Схема валидации для полей feeTrader, feeReferrer и fromDate
const feeSchema = yup.object().shape({
  feeTrader: yup
    .number()
    .typeError("Введите корректное значение комиссии")
    .min(0, "Комиссия не может быть меньше 0")
    .required("Комиссия обязательна"),
  feeReferrer: yup
    .number()
    .typeError("Введите корректное значение комиссии")
    .min(0, "Комиссия не может быть меньше 0")
    .required("Комиссия обязательна"),
  fromDate: yup.string().when(["isDeferredTrader", "isDeferredReferrer"], {
    is: (isDeferredTrader: boolean, isDeferredReferrer: boolean) =>
      isDeferredTrader || isDeferredReferrer,
    then: (schema) =>
      schema.required("Дата обязательна для отложенного запуска"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

interface FormData {
  feeTrader: number;
  feeReferrer: number;
  fromDate: string;
  isDeferredTrader: boolean;
  isDeferredReferrer: boolean;
}

const Fees: React.FC = () => {
  const { mutate: setFee } = useSetFees();
  const { defaultRefererFee, refetch: refetchReferer } =
    useGetDefaultRefererFee();
  const { defaultTraderFee, refetch: refetchTrader } = useGetDefaultTraderFee();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<FormData>({
    // @ts-ignore
    resolver: yupResolver(feeSchema),
    defaultValues: {
      feeTrader: 0,
      feeReferrer: 0,
      fromDate: dayjs().format("YYYY-MM-DD"), // Текущая дата по умолчанию
      isDeferredTrader: false,
      isDeferredReferrer: false,
    },
  });

  const watchDeferredTrader = watch("isDeferredTrader");
  const watchDeferredReferrer = watch("isDeferredReferrer");
  const watchFeeTrader = watch("feeTrader");
  const watchFeeReferrer = watch("feeReferrer");

  // Обработчик отправки формы
  const onSubmit = (data: FormData, userType: number) => {
    const isDeferred =
      userType === 4 ? watchDeferredTrader : watchDeferredReferrer;

    const payload = {
      userId: 0, // Указываем 0, так как это общая комиссия
      userType,
      fee: userType === 4 ? data.feeTrader : data.feeReferrer,
      ...(isDeferred && { fromDate: data.fromDate }), // Добавляем дату, если включен отложенный запуск
    };

    setFee(payload, {
      onSuccess: () => {
        toast.success("Комиссия успешно обновлена");
        reset();
        refetchReferer();
        refetchTrader();
      },
      onError: () => {
        toast.error("Ошибка при обновлении комиссии");
      },
    });
  };

  // Общие пропсы для полей ввода
  const inputFieldProps = (
    name: string,
    placeholder: string,
    type: string = "text"
  ) => ({
    id: name,
    className: "form-control",
    type,
    placeholder,
  });

  // Функция для рендеринга формы
  const renderFeeForm = (
    title: string,
    currentFee: number,
    userType: number,
    isDeferred: boolean,
    deferredFieldName: "isDeferredTrader" | "isDeferredReferrer",
    feeFieldName: "feeTrader" | "feeReferrer"
  ) => (
    <div className="fees__item">
      <h2 className="fees__item-title">{title}</h2>
      <div className="fees__current">Текущее значение {currentFee}%</div>
      <form
        className="fees__form form"
        onSubmit={handleSubmit((data) => onSubmit(data, userType))}
      >
        <div className="form__items">
          {/* Поле для ввода новой комиссии */}
          <div className="form__item">
            <label htmlFor={feeFieldName} className="form__label">
              Новое значение
            </label>
            <input
              {...inputFieldProps(
                feeFieldName,
                "Введите новое значение",
                "number"
              )}
              value={userType === 4 ? watchFeeTrader : watchFeeReferrer} // Используем значение из watch
              onChange={(e) =>
                setValue(feeFieldName, parseFloat(e.target.value))
              } // Обновляем значение в форме
            />
            {errors[feeFieldName] && (
              <p className="error">{errors[feeFieldName]?.message}</p>
            )}
          </div>

          {/* Поле для ввода даты, если включен отложенный запуск */}
          {isDeferred && (
            <div className="form__item">
              <label htmlFor="fromDate" className="form__label">
                Дата и время начала действия
              </label>
              <input
                {...inputFieldProps("fromDate", "", "datetime-local")}
                {...register("fromDate")}
              />
              {errors.fromDate && (
                <p className="error">{errors.fromDate.message}</p>
              )}
            </div>
          )}
        </div>

        {/* Чекбокс для отложенного запуска */}
        <Controller
          name={deferredFieldName}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="fees__checkbox checkbox">
              <input
                id={deferredFieldName}
                type="checkbox"
                className="checkbox__input"
                checked={value}
                onChange={onChange}
              />
              <label htmlFor={deferredFieldName} className="checkbox__label">
                Отложенный запуск
              </label>
            </div>
          )}
        />

        {/* Кнопка отправки формы */}
        <button type="submit" className="button">
          Изменить
        </button>
      </form>
    </div>
  );

  return (
    <main className="main fees">
      <h1 className="fees__title title">Комиссии и начисления</h1>
      <div className="fees__items">
        {/* Форма для трейдера */}
        {renderFeeForm(
          "Изменить общую комиссию трейдера",
          defaultTraderFee,
          4,
          watchDeferredTrader,
          "isDeferredTrader",
          "feeTrader"
        )}

        {/* Форма для реферера */}
        {renderFeeForm(
          "Изменить общее начисление реферера",
          defaultRefererFee,
          2,
          watchDeferredReferrer,
          "isDeferredReferrer",
          "feeReferrer"
        )}
      </div>
    </main>
  );
};

export default Fees;
