import React from "react";
import {
  useConfirmPaymentWithdrawal,
  useGetWithdrawalList,
  useRejectPaymentWithdrawal,
} from "../../services/withdrawals.services";
import Loading from "../../components/Loading";
import toast from "react-hot-toast";

const Withdrawals: React.FC = () => {
  const { withdrawalList, isLoading, refetch } = useGetWithdrawalList({
    page: 1,
  });

  const { mutate: confirmWithdrawalDeposit } = useConfirmPaymentWithdrawal(
    () => {
      toast.success("Вывод успешно подтвержден!");
      refetch();
    },
    () => {
      toast.error("Что-то пошло не так... Попробуйте позже!");
    }
  );

  const { mutate: rejectPaymentWithdrawal } = useRejectPaymentWithdrawal(
    () => {
      toast.success("Вывод успешно отклонен!");
      refetch();
    },
    () => {
      toast.error("Что-то пошло не так... Попробуйте позже!");
    }
  );

  // Функция для рендеринга статуса вывода
  const renderWithdrawalStatus = (status: number, id: number) => {
    switch (status) {
      case 3: // Ожидание
        return (
          <div className="table__item">
            <div className="table__item-action">
              <button
                onClick={() => rejectPaymentWithdrawal(id)}
                className="table__item-img"
              >
                <img
                  className="table__item-icon"
                  src="/img/svg/decline.svg"
                  alt="Отклонить"
                />
              </button>
              <button
                onClick={() => confirmWithdrawalDeposit(id)}
                className="table__item-img"
              >
                <img
                  className="table__item-icon"
                  src="/img/svg/confirm.svg"
                  alt="Подтвердить"
                />
              </button>
            </div>
          </div>
        );
      case 4: // Подтверждено
        return (
          <div className="table__item">
            <button className="table__item-img">
              <img
                className="table__item-icon"
                src="/img/svg/confirmed.svg"
                alt="Подтверждено"
              />
            </button>
          </div>
        );
      case 6: // Отклонено
        return (
          <div className="table__item">
            <button className="table__item-img">
              <img
                className="table__item-icon"
                src="/img/svg/rejected.svg"
                alt="Отклонено"
              />
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      <div className="exchange__header">
        <h1 className="exchange__title title">Управление выводами</h1>
      </div>

      <div className="exchange__table table-exchange table">
        {/* Заголовок таблицы */}
        <div className="table-currency__items table__items table__items--head">
          <div className="table__item">#</div>
          <div className="table__item">Имя Пользователя</div>
          <div className="table__item">Сумма</div>
          <div className="table__item">Адрес Кошелька</div>
          <div className="table__item">Статус</div>
        </div>

        {/* Строки таблицы */}
        {withdrawalList?.map((withdraw: any) => (
          <div key={withdraw.id} className="table-currency__items table__items">
            <div className="table__item">{withdraw.id}</div>
            <div className="table__item">{withdraw.user.userName}</div>
            <div className="table__item">{withdraw.amount}</div>
            <div className="table__item" title={withdraw.user_wallet}>
              {withdraw.user_wallet}
            </div>
            {renderWithdrawalStatus(withdraw.status, withdraw.id)}
          </div>
        ))}
      </div>
    </main>
  );
};

export default Withdrawals;
