import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";

interface IProps {
  returnFormInstance: UseFormReturn<{
    fee: number;
    fromDate?: string;
  }>;
  onSubmit: (data: { fee: number; fromDate?: string }) => void;
  user: any;
}

const UpdateUserFeeModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
  user,
}) => {
  const [isDeferred, setIsDeferred] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = returnFormInstance;

  // Общие пропсы для полей ввода
  const inputFieldProps = (type: string, defaultValue?: string | number) => ({
    className: "form-control",
    type,
    defaultValue,
  });

  return (
    <div className="fees">
      <div className="fees__current">Текущее значение: {user?.fee}%</div>
      <form onSubmit={handleSubmit(onSubmit)} className="fees__form form">
        <div className="form__items">
          {/* Поле для ввода нового значения комиссии */}
          <div className="form__item">
            <label htmlFor="fee" className="form__label">
              Новое значение
            </label>
            <input
              {...inputFieldProps("text", user?.fee)}
              {...register("fee")}
            />
            {errors.fee && <p className="error">{errors.fee.message}</p>}
          </div>

          {/* Поле для ввода даты и времени (если включен отложенный запуск) */}
          {isDeferred && (
            <div className="form__item">
              <label htmlFor="fromDate" className="form__label">
                Дата и время начала действия
              </label>
              <input
                {...inputFieldProps("datetime-local")}
                {...register("fromDate")}
              />
              {errors.fromDate && (
                <p className="error">{errors.fromDate.message}</p>
              )}
            </div>
          )}
        </div>

        {/* Чекбокс для отложенного запуска */}
        <div className="fees__checkbox checkbox">
          <input
            id="deferredCheckbox"
            type="checkbox"
            className="checkbox__input"
            checked={isDeferred}
            onChange={() => setIsDeferred((prev) => !prev)}
          />
          <label htmlFor="deferredCheckbox" className="checkbox__label">
            Отложенный запуск
          </label>
        </div>

        {/* Кнопка отправки формы */}
        <button type="submit" className="button">
          Изменить
        </button>
      </form>
    </div>
  );
};

export default UpdateUserFeeModal;
