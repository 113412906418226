import React from "react";

interface IProps {
  text: string;
}

const EmptyList: React.FC<IProps> = ({ text }) => {
  return (
    <div
      style={{
        width: "90%",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
      }}
    >
      <p className="empty__list-text">{text}</p>
    </div>
  );
};

export default EmptyList;
