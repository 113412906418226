import React, { useState } from "react";
import * as yup from "yup";
import {
  useAddCurrency,
  useDeleteCurrency,
  useGetCurrencyList,
  useUpdateCurrency,
  useGetCurrencyById,
} from "../../services/currencies.services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICurrencyRequestData } from "../../models/currency.models";
import Modal from "../../components/Modal";
import AddCurrencyModal from "./AddCurrencyModal";
import EditCurrencyModal from "./EditCurrencyModal";
import Loading from "../../components/Loading";

// Схема валидации для добавления валюты
const AddCurrencyFormSchema = yup.object().shape({
  code: yup.string().required("Код валюты является обязательным полем!"),
  name: yup
    .string()
    .required("Полное название валюты является обязательным полем!"),
  symbol: yup.string().required("Символ валюты является обязательным полем!"),
  limit: yup.number().required("Лимит для валюты является обязательным полем!"),
});

const Currencies: React.FC = () => {
  const [isAddCurrencyModalOpen, setIsAddCurrencyModalOpen] = useState(false);
  const [isEditCurrencyModalOpen, setIsEditCurrencyModalOpen] = useState(false);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<number | null>(
    null
  );

  // Запросы данных
  const { currencyList, refetch, isLoading } = useGetCurrencyList();
  const { currency, isLoading: isLoadingCurrency } = useGetCurrencyById(
    selectedCurrencyId?.toString()!
  );

  // Форма для добавления валюты
  const useAddReturnForm = useForm<ICurrencyRequestData>({
    resolver: yupResolver(AddCurrencyFormSchema),
  });
  const { reset } = useAddReturnForm;

  // Мутации
  const { mutate: addCurrency } = useAddCurrency(
    () => {
      toast.success("Валюта успешно добавлена!");
      setIsAddCurrencyModalOpen(false);
      reset();
      refetch();
    },
    () => toast.error("Не удалось добавить валюту. Попробуйте позже.")
  );

  const { mutate: deleteCurrency } = useDeleteCurrency(
    () => {
      toast.success("Валюта успешно удалена!");
      refetch();
    },
    () => toast.error("Не удалось удалить валюту. Попробуйте позже.")
  );

  const { mutate: updateCurrency } = useUpdateCurrency(
    () => {
      toast.success("Валюта успешно обновлена!");
      setIsEditCurrencyModalOpen(false);
      refetch();
    },
    () => toast.error("Не удалось обновить валюту. Попробуйте позже.")
  );

  // Обработчики
  const handleSubmitAddForm = (data: ICurrencyRequestData) => {
    addCurrency(data);
  };

  const handleDeleteCurrency = (id: number) => {
    if (window.confirm("Вы уверены, что хотите удалить эту валюту?")) {
      deleteCurrency(id.toString());
    }
  };

  const handleEditCurrency = (id: number) => {
    setSelectedCurrencyId(id);
    setIsEditCurrencyModalOpen(true);
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      <div className="exchange__header">
        <h1 className="exchange__title title">Управление валютами</h1>
        <button
          onClick={() => setIsAddCurrencyModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить валюту
        </button>
      </div>

      {/* Таблица с валютами */}
      <div className="exchange__table table-exchange table">
        <div className="table-currency__items table__items table__items--head">
          <div className="table__item">Код</div>
          <div className="table__item">Название</div>
          <div className="table__item">Символ</div>
          <div className="table__item">Лимит</div>
          <div className="table__item">Действия</div>
        </div>
        {currencyList?.map((currency) => (
          <div key={currency.id} className="table-currency__items table__items">
            <div className="table__item">{currency.code}</div>
            <div className="table__item">{currency.name}</div>
            <div className="table__item">{currency.symbol}</div>
            <div className="table__item">{currency.limit}</div>
            <div className="table__item">
              <div className="table__item-action">
                <button
                  onClick={() => handleEditCurrency(currency.id)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/edit.svg"
                    alt="Редактировать"
                  />
                </button>
                <button
                  onClick={() => handleDeleteCurrency(currency.id)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/del.svg"
                    alt="Удалить"
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Модальное окно добавления валюты */}
      <Modal
        isOpen={isAddCurrencyModalOpen}
        onClose={() => setIsAddCurrencyModalOpen(false)}
        title="Добавить валюту"
      >
        <AddCurrencyModal
          returnFormInstance={useAddReturnForm}
          onSubmit={handleSubmitAddForm}
        />
      </Modal>

      {/* Модальное окно редактирования валюты */}
      <Modal
        isOpen={isEditCurrencyModalOpen}
        onClose={() => setIsEditCurrencyModalOpen(false)}
        title="Редактировать валюту"
      >
        {isLoadingCurrency ? (
          <Loading />
        ) : (
          currency && (
            <EditCurrencyModal currency={currency} onSubmit={updateCurrency} />
          )
        )}
      </Modal>
    </main>
  );
};

export default Currencies;
