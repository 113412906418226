import React, { useState } from "react";
import * as yup from "yup";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import NewLanguageModal from "./NewLanguageModal";
import {
  useAddLanguage,
  useDeleteLanguage,
  useGetLanguages,
} from "../../services/language.services";

// Схема валидации для формы добавления языка
const NewLanguageFormSchema = yup.object().shape({
  code: yup.string().required("Код языка является обязательным полем!"),
  name: yup.string().required("Название языка является обязательным полем!"),
  file_path: yup
    .string()
    .required("Языковой пакет является обязательным полем!"),
});

const Languages: React.FC = () => {
  const [isNewLanguageModalOpen, setIsNewLanguageModalOpen] =
    useState<boolean>(false);

  const {
    languages,
    refetch,
    isLoading,
    isError: isLanguagesError,
  } = useGetLanguages();

  const useNewLanguageReturnForm = useForm({
    resolver: yupResolver(NewLanguageFormSchema),
  });

  const { reset } = useNewLanguageReturnForm;

  // Обработчик успешного добавления языка
  const onAddLanguageSuccess = () => {
    setIsNewLanguageModalOpen(false);
    toast.success("Язык успешно добавлен!");
    reset();
    refetch();
  };

  // Обработчик ошибки при добавлении языка
  const onAddLanguageError = () => {
    toast.error("Ошибка при добавлении языка!");
  };

  // Обработчик успешного удаления языка
  const onDeleteLanguageSuccess = () => {
    toast.success("Язык успешно удален!");
    refetch();
  };

  // Обработчик ошибки при удалении языка
  const onDeleteLanguageError = () => {
    toast.error("Ошибка при удалении языка!");
  };

  const { mutate: addLanguage } = useAddLanguage(
    onAddLanguageSuccess,
    onAddLanguageError
  );
  const { mutate: deleteLanguage } = useDeleteLanguage(
    onDeleteLanguageSuccess,
    onDeleteLanguageError
  );

  // Обработчик отправки формы добавления языка
  const handleSubmitNewLanguageForm = (data: {
    code: string;
    name: string;
    file_path: string;
  }) => {
    try {
      addLanguage(data);
    } catch (error) {
      toast.error("Произошла ошибка при отправке формы!");
      console.error("Ошибка при отправке формы: ", error);
    }
  };

  // Обработчик удаления языка
  const handleDeleteLanguage = (id: string) => {
    try {
      deleteLanguage(id);
    } catch (error) {
      toast.error("Произошла ошибка при удалении языка!");
      console.error("Ошибка при удалении языка: ", error);
    }
  };

  if (isLoading) return <Loading />;
  if (isLanguagesError)
    return <p className="error">Ошибка при загрузке языков!</p>;

  return (
    <main className="main exchange">
      <div className="exchange__header">
        <h1 className="exchange__title title">Доступные языки</h1>
        <button
          onClick={() => setIsNewLanguageModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить новый язык
        </button>
      </div>

      {/* Таблица с языками */}
      <div className="exchange__table table-exchange table">
        <div className="table-exchange__items table__items table__items--head">
          <div className="table__item">ID</div>
          <div className="table__item">Код</div>
          <div className="table__item">Название</div>
          <div className="table__item"></div>
          <div className="table__item"></div>
        </div>
        {languages?.map(
          (language: { id: string; name: string; code: string }) => (
            <div
              className="table-exchange__items table__items"
              key={language.id}
            >
              <div className="table__item">{language.id}</div>
              <div className="table__item">{language.code}</div>
              <div className="table__item">{language.name}</div>
              <div className="table__item"></div>
              <div className="table__item">
                <div className="table__item-action">
                  <button
                    onClick={() => handleDeleteLanguage(language.id)}
                    className="table__item-img"
                  >
                    <img
                      className="table__item-icon"
                      src="/img/svg/del.svg"
                      alt="Удалить"
                    />
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      {/* Модальное окно для добавления нового языка */}
      <Modal
        title="Новый язык"
        isOpen={isNewLanguageModalOpen}
        onClose={() => setIsNewLanguageModalOpen(false)}
      >
        <NewLanguageModal
          returnFormInstance={useNewLanguageReturnForm}
          onSubmit={handleSubmitNewLanguageForm}
        />
      </Modal>
    </main>
  );
};

export default Languages;
