import React from "react";
import { Link, NavLink } from "react-router-dom";
import { ROUTES } from "../../routes/routes";

const Navbar: React.FC = () => {
  return (
    <aside className="sidebar">
      <Link to={ROUTES.MAIN} className="sidebar__logo">
        <div className="logo">
          <img className="logo__icon" src="/img/logo.svg" alt="logo bobster" />
          <div className="logo__text">
            <div className="logo__name">BOBSTER</div>
            <div className="logo__descr">PAYMENTS</div>
          </div>
        </div>
      </Link>
      <nav className="sidebar__nav nav">
        <ul className="nav__list">
          <li className="nav__item">
            <NavLink
              to={ROUTES.USERS}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#users"></use>
                </svg>
              </div>
              <span className="nav__link-text">Пользователи</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.DEPOSITS}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use href="/img/sprite.svg#deposit"></use>
                </svg>
              </div>
              <span className="nav__link-text">Депозиты</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.WITHDRAWALS}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use href="/img/sprite.svg#withdraw"></use>
                </svg>
              </div>
              <span className="nav__link-text">Выводы</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.EXCHANGE}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#tables"></use>
                </svg>
              </div>
              <span className="nav__link-text">Управление курсами</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.CURRENCIES}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <p className="nav__link-icon">$</p>
              </div>
              <span className="nav__link-text">Валюта</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.HISTORY}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#document"></use>
                </svg>
              </div>
              <span className="nav__link-text">История транзакций</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.CHATS}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#chat"></use>
                </svg>
              </div>
              <span className="nav__link-text">Чаты</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.FEES}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#dollar"></use>
                </svg>
              </div>
              <span className="nav__link-text">Комиссии и начисления</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.COUNTRIES}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#country"></use>
                </svg>
              </div>
              <span className="nav__link-text">Страны</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.CITIES}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#city"></use>
                </svg>
              </div>
              <span className="nav__link-text">Города</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.ADDRESSES}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#addresses"></use>
                </svg>
              </div>
              <span className="nav__link-text">Адреса выдачи Cash</span>
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to={ROUTES.LANGUAGES}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <div className="nav__link-img">
                <svg className="nav__link-icon">
                  <use xlinkHref="/img/sprite.svg#languages"></use>
                </svg>
              </div>
              <span className="nav__link-text">Языки</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Navbar;
