import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "./routes";
import Main from "../views/Users";
import RootWrapper from "../components/RootWrapper";
import Exchange from "../views/Exchange";
import History from "../views/History";
import Chats from "../views/Chats";
import Fees from "../views/Fees";
import Addresses from "../views/Addresses";
import Login from "../views/Auth/Login";
import Registration from "../views/Auth/Registration";
import PrivateRoute from "../components/PrivateRoute"; // Добавьте импорт
import Currencies from "../views/Currencies";
import Countries from "../views/Countries";
import Cities from "../views/Cities";
import Languages from "../views/Languages";
import Deposits from "../views/Deposits";
import Withdrawals from "../views/Withdrawal";

const RoutesWrapper: React.FC = () => {
  return (
    <Routes>
      {/* Открытые маршруты */}
      <Route path={ROUTES.LOGIN} Component={Login} />
      <Route path={ROUTES.REGISTRATION} Component={Registration} />

      {/* Все защищенные маршруты оборачиваем в PrivateRoute */}
      <Route path={ROUTES.MAIN} element={<Navigate to={ROUTES.USERS} />} />
      <Route element={<PrivateRoute />}>
        <Route path={ROUTES.MAIN} Component={RootWrapper}>
          <Route path={ROUTES.USERS} Component={Main} />
          <Route path={ROUTES.EXCHANGE} Component={Exchange} />
          <Route path={ROUTES.HISTORY} Component={History} />
          <Route path={ROUTES.CHATS} Component={Chats} />
          <Route path={ROUTES.FEES} Component={Fees} />
          <Route path={ROUTES.ADDRESSES} Component={Addresses} />
          <Route path={ROUTES.CURRENCIES} Component={Currencies} />
          <Route path={ROUTES.CITIES} Component={Cities} />
          <Route path={ROUTES.COUNTRIES} Component={Countries} />
          <Route path={ROUTES.LANGUAGES} Component={Languages} />
          <Route path={ROUTES.DEPOSITS} Component={Deposits} />
          <Route path={ROUTES.WITHDRAWALS} Component={Withdrawals} />
        </Route>
      </Route>
    </Routes>
  );
};

export default RoutesWrapper;
