import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import NewCityModal from "./NewCityModal";
import {
  useAddCity,
  useDeleteCity,
  useGetCountries,
} from "../../services/addresses.services";
import authApi from "../../config/authApi.config";

// Схема валидации для формы добавления города
const NewCityFormSchema = yup.object().shape({
  countryId: yup.number().required("Выберите страну!"),
  city: yup.string().required("Название города является обязательным полем!"),
});

const Cities: React.FC = () => {
  const [isNewCityModalOpen, setIsNewCityModalOpen] = useState<boolean>(false);
  const [citiesData, setCitiesData] = useState<any[]>([]);
  const [loadingCities, setLoadingCities] = useState<boolean>(false);

  const useNewCityReturnForm = useForm({
    resolver: yupResolver(NewCityFormSchema),
  });

  const { reset } = useNewCityReturnForm;

  const { countries, isLoading: isLoadingCountries } = useGetCountries();

  // Функция для загрузки городов по ID страны
  const fetchCitiesByCountry = async (countryId: number) => {
    try {
      const response = await authApi.get(`/addresses/cities`, {
        params: { countryId },
      });
      return response.data;
    } catch (error) {
      console.error(
        `Ошибка при загрузке городов для страны ${countryId}:`,
        error
      );
      throw error;
    }
  };

  // Загрузка городов для всех стран
  const fetchCitiesForAllCountries = async () => {
    if (!countries || countries.length === 0) return;

    setLoadingCities(true);
    try {
      const citiesRequests = countries.map((country: any) =>
        fetchCitiesByCountry(country.id)
      );
      const citiesResults = await Promise.all(citiesRequests);
      const allCities = citiesResults.flat();
      setCitiesData(allCities);
    } catch (error) {
      toast.error("Ошибка при загрузке городов");
      console.error("Ошибка при загрузке городов: ", error);
    } finally {
      setLoadingCities(false);
    }
  };

  // Загружаем города при изменении списка стран
  useEffect(() => {
    fetchCitiesForAllCountries();
  }, [countries]);

  // Обработчик успешного добавления города
  const onAddCitySuccess = () => {
    setIsNewCityModalOpen(false);
    toast.success("Город успешно добавлен!");
    reset();
    fetchCitiesForAllCountries(); // Перезагружаем города
  };

  // Обработчик ошибки при добавлении города
  const onAddCityError = () => {
    toast.error("Ошибка при добавлении города!");
  };

  // Обработчик успешного удаления города
  const onDeleteCitySuccess = () => {
    toast.success("Город успешно удалён!");
    fetchCitiesForAllCountries(); // Перезагружаем города
  };

  // Обработчик ошибки при удалении города
  const onDeleteCityError = () => {
    toast.error("Ошибка при удалении города!");
  };

  const { mutate: addCity } = useAddCity(onAddCitySuccess, onAddCityError);
  const { mutate: deleteCity } = useDeleteCity(
    onDeleteCitySuccess,
    onDeleteCityError
  );

  // Обработчик отправки формы добавления города
  const handleSubmitNewCityForm = (data: {
    countryId: number;
    city: string;
  }) => {
    try {
      addCity(data);
    } catch (error) {
      toast.error("Произошла ошибка при отправке формы!");
      console.error("Ошибка при отправке формы: ", error);
    }
  };

  // Обработчик удаления города
  const handleDeleteCity = (id: number) => {
    try {
      deleteCity(id);
    } catch (error) {
      toast.error("Произошла ошибка при удалении города!");
      console.error("Ошибка при удалении города: ", error);
    }
  };

  if (isLoadingCountries || loadingCities) return <Loading />;

  return (
    <main className="main exchange">
      <div className="exchange__header">
        <h1 className="exchange__title title">Доступные города</h1>
        <button
          onClick={() => setIsNewCityModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить новый город
        </button>
      </div>

      {/* Таблица с городами */}
      <div className="exchange__table table-exchange table">
        <div className="table-exchange__items table__items table__items--head">
          <div className="table__item">ID</div>
          <div className="table__item">Страна</div>
          <div className="table__item">Название города</div>
          <div className="table__item"></div>
          <div className="table__item"></div>
        </div>
        {citiesData?.map(
          (city: { id: number; city: string; country: number }) => (
            <div className="table-exchange__items table__items" key={city.id}>
              <div className="table__item">{city.id}</div>
              <div className="table__item">
                {
                  countries?.find((item: any) => item.id === +city.country)
                    ?.country
                }
              </div>
              <div className="table__item">{city.city}</div>
              <div className="table__item"></div>
              <div className="table__item">
                <button
                  onClick={() => handleDeleteCity(city.id)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/del.svg"
                    alt="Удалить"
                  />
                </button>
              </div>
            </div>
          )
        )}
      </div>

      {/* Модальное окно для добавления нового города */}
      <Modal
        title="Новый город"
        isOpen={isNewCityModalOpen}
        onClose={() => setIsNewCityModalOpen(false)}
      >
        <NewCityModal
          returnFormInstance={useNewCityReturnForm}
          onSubmit={handleSubmitNewCityForm}
        />
      </Modal>
    </main>
  );
};

export default Cities;
