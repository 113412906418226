import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getDepositList = async (
  page: number,
  limit?: number,
  keyword?: string
) => {
  return authApi
    .get(
      `/payments/findDepositData?limit=${limit || 10}&page=${page}${
        keyword ? `&keyword=${keyword}` : ``
      }`
    )
    .then((res) => res.data);
};

const confirmPaymentDeposit = async (paymentId: number) => {
  return authApi
    .patch("/payments/confirmDeposit", { paymentId })
    .then((res) => res.data);
};

const rejectPaymentDeposit = async (paymentId: number) => {
  return authApi
    .patch("/payments/rejectDeposit", { paymentId })
    .then((res) => res.data);
};

export const useRejectPaymentDeposit = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["RejectDeposit"],
    mutationFn: (paymentId: number) => rejectPaymentDeposit(paymentId),
    onSuccess,
    onError,
  });
};

export const useConfirmPaymentDeposit = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["ConfirmDeposit"],
    mutationFn: (paymentId: number) => confirmPaymentDeposit(paymentId),
    onSuccess,
    onError,
  });
};

export const useGetDepositList = (data: {
  page: number;
  limit?: number;
  keyword?: string;
}) => {
  const {
    isLoading,
    isError,
    data: depositList,
    refetch,
  } = useQuery({
    queryKey: ["DepositList"],
    queryFn: () => getDepositList(data.page, data.limit, data.keyword),
  });

  return {
    isLoading,
    isError,
    depositList,
    refetch,
  };
};
