import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { IAddCurrencyPairRequestData } from "../../models/currency-pairs.models";
import { useGetCurrencyList } from "../../services/currencies.services";

interface IProps {
  returnFormInstance: UseFormReturn<IAddCurrencyPairRequestData>;
  onSubmit: (data: IAddCurrencyPairRequestData) => void;
}

const NewCurrencyPairModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
}) => {
  const { currencyList, isLoading: isCurrencyListLoading } =
    useGetCurrencyList();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  // Общие пропсы для полей ввода
  const inputFieldProps = (
    name: string,
    placeholder: string,
    type: string = "text",
    step: string = "0.01"
  ) => ({
    id: name,
    className: "form-control",
    type,
    placeholder,
    step,
  });

  // Функция для рендеринга выпадающего списка валют
  const renderCurrencySelect = (
    name: keyof IAddCurrencyPairRequestData,
    label: string,
    errorMessage: string | undefined
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className="create-admin__item form__item">
          <label htmlFor={name} className="form__label">
            {label}
          </label>
          <select
            id={name}
            className="form-control"
            value={value}
            onChange={onChange}
            disabled={isCurrencyListLoading}
          >
            <option value="">Выберите валюту</option>
            {currencyList?.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.code} - {currency.name}
              </option>
            ))}
          </select>
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>
      )}
    />
  );

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        {/* Первая валюта */}
        {renderCurrencySelect(
          "baseCurrencyId",
          "Первая Валюта",
          errors.baseCurrencyId?.message
        )}

        {/* Вторая валюта */}
        {renderCurrencySelect(
          "targetCurrencyId",
          "Вторая Валюта",
          errors.targetCurrencyId?.message
        )}

        {/* Обменный курс */}
        <Controller
          name="exchangeRate"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="exchangeRate" className="form__label">
                Обменный курс
              </label>
              <input
                {...inputFieldProps(
                  "exchangeRate",
                  "Введите курс обмена",
                  "number",
                  "0.00000000001"
                )}
                value={value}
                onChange={onChange}
              />
              {errors.exchangeRate?.message && (
                <p className="error">{errors.exchangeRate?.message}</p>
              )}
            </div>
          )}
        />

        {/* Профит */}
        <Controller
          name="profit"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="profit" className="form__label">
                Профит, %
              </label>
              <input
                {...inputFieldProps(
                  "profit",
                  "Введите профит от данного курса",
                  "number",
                  "0.00000000000000001"
                )}
                value={value}
                onChange={onChange}
              />
              {errors.profit?.message && (
                <p className="error">{errors.profit?.message}</p>
              )}
            </div>
          )}
        />

        {/* Кнопка отправки формы */}
        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Создать
        </button>
      </form>
    </div>
  );
};

export default NewCurrencyPairModal;
