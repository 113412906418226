import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ICurrency } from "../../models/currency.models";

interface IProps {
  currency: ICurrency; // Получаем валюту для редактирования
  onSubmit: (data: ICurrency) => void;
}

const EditCurrencyModal: React.FC<IProps> = ({ currency, onSubmit }) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty, errors },
  } = useForm<ICurrency>({
    defaultValues: {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      symbol: currency.symbol,
      limit: currency.limit,
    },
  });

  const handleSubmitForm = () => {
    const data = getValues();
    onSubmit(data);
  };

  // Общие пропсы для полей ввода
  const inputFieldProps = (
    name: string,
    placeholder: string,
    type: string = "text",
    maxLength?: number
  ) => ({
    id: name,
    className: "form-control",
    type,
    placeholder,
    maxLength,
  });

  // Функция для рендеринга поля ввода
  const renderInputField = (
    name: keyof ICurrency,
    label: string,
    placeholder: string,
    type: string = "text",
    maxLength?: number
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="create-admin__item form__item">
          <label htmlFor={name} className="form__label">
            {label}
          </label>
          <input
            {...inputFieldProps(name, placeholder, type, maxLength)}
            {...field}
          />
          {errors[name]?.message && (
            <p className="error">{errors[name]?.message}</p>
          )}
        </div>
      )}
    />
  );

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        className="create-admin__form form"
      >
        {/* Поле для ввода кода валюты */}
        {renderInputField("code", "Код валюты", "USD", "text", 10)}

        {/* Поле для ввода названия валюты */}
        {renderInputField(
          "name",
          "Название",
          "United States Dollar",
          "text",
          60
        )}

        {/* Поле для ввода символа валюты */}
        {renderInputField("symbol", "Символ", "$", "text", 10)}

        {/* Поле для ввода лимита */}
        {renderInputField("limit", "Лимит", "1000", "number", 12)}

        {/* Кнопка отправки формы */}
        <button
          type="submit"
          disabled={!isDirty}
          className="create-admin__button button"
        >
          Сохранить изменения
        </button>
      </form>
    </div>
  );
};

export default EditCurrencyModal;
