import React from "react";
import {
  useConfirmPaymentDeposit,
  useGetDepositList,
  useRejectPaymentDeposit,
} from "../../services/deposits.services";
import Loading from "../../components/Loading";
import toast from "react-hot-toast";

const Deposits: React.FC = () => {
  const { depositList, isLoading, refetch } = useGetDepositList({ page: 1 });

  const { mutate: confirmPaymentDeposit } = useConfirmPaymentDeposit(
    () => {
      toast.success("Пополнение успешно подтверждено!");
      refetch();
    },
    () => {
      toast.error("Что-то пошло не так... Попробуйте позже!");
    }
  );

  const { mutate: rejectPaymentDeposit } = useRejectPaymentDeposit(
    () => {
      toast.success("Пополнение успешно отклонено!");
      refetch();
    },
    () => {
      toast.error("Что-то пошло не так... Попробуйте позже!");
    }
  );

  // Функция для рендеринга статуса депозита
  const renderDepositStatus = (status: number, deposit: any) => {
    switch (status) {
      case 1: // Ожидание
        return (
          <div className="table__item">
            <div className="table__item-action">
              <button
                onClick={() => rejectPaymentDeposit(deposit?.id)}
                className="table__item-img"
              >
                <img
                  className="table__item-icon"
                  src="/img/svg/decline.svg"
                  alt="Отклонить"
                />
              </button>
              <button
                onClick={() => confirmPaymentDeposit(deposit.id)}
                className="table__item-img"
              >
                <img
                  className="table__item-icon"
                  src="/img/svg/confirm.svg"
                  alt="Подтвердить"
                />
              </button>
            </div>
          </div>
        );
      case 2: // Подтверждено
        return (
          <div className="table__item">
            <button className="table__item-img">
              <img
                className="table__item-icon"
                src="/img/svg/confirmed.svg"
                alt="Подтверждено"
              />
            </button>
          </div>
        );
      case 3: // Отклонено
        return (
          <div className="table__item">
            <button className="table__item-img">
              <img
                className="table__item-icon"
                src="/img/svg/rejected.svg"
                alt="Отклонено"
              />
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      <div className="exchange__header">
        <h1 className="exchange__title title">Управление пополнениями</h1>
      </div>

      <div className="exchange__table table-exchange table">
        {/* Заголовок таблицы */}
        <div className="table-currency__items table__items table__items--head">
          <div className="table__item">#</div>
          <div className="table__item">Имя Пользователя</div>
          <div className="table__item">Сумма</div>
          <div className="table__item">Адрес Кошелька</div>
          <div className="table__item">Статус</div>
        </div>

        {/* Строки таблицы */}
        {depositList?.map((deposit: any) => (
          <div key={deposit.id} className="table-currency__items table__items">
            <div className="table__item">{deposit.id}</div>
            <div className="table__item">{deposit.user.userName}</div>
            <div className="table__item">{deposit.amount}</div>
            <div className="table__item" title={deposit.user_wallet}>
              {deposit.user_wallet}
            </div>
            {renderDepositStatus(deposit.status, deposit)}
          </div>
        ))}
      </div>
    </main>
  );
};

export default Deposits;
