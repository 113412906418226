import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { ICurrencyRequestData } from "../../models/currency.models";

interface IProps {
  returnFormInstance: UseFormReturn<ICurrencyRequestData>;
  onSubmit: (data: ICurrencyRequestData) => void;
}

const AddCurrencyModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  // Общие пропсы для полей ввода
  const inputFieldProps = (
    name: string,
    placeholder: string,
    type: string = "text",
    maxLength?: number
  ) => ({
    id: name,
    className: "form-control",
    type,
    placeholder,
    maxLength,
  });

  // Функция для рендеринга поля ввода
  const renderInputField = (
    name: keyof ICurrencyRequestData,
    label: string,
    placeholder: string,
    type: string = "text",
    maxLength?: number
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className="create-admin__item form__item">
          <label htmlFor={name} className="form__label">
            {label}
          </label>
          <input
            {...inputFieldProps(name, placeholder, type, maxLength)}
            value={value}
            onChange={onChange}
          />
          {errors[name]?.message && (
            <p className="error">{errors[name]?.message}</p>
          )}
        </div>
      )}
    />
  );

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        {/* Поле для ввода кода валюты */}
        {renderInputField("code", "Код валюты", "USD", "text", 10)}

        {/* Поле для ввода названия валюты */}
        {renderInputField(
          "name",
          "Название",
          "United States Dollar",
          "text",
          60
        )}

        {/* Поле для ввода символа валюты */}
        {renderInputField("symbol", "Символ", "$", "text", 10)}

        {/* Поле для ввода лимита */}
        {renderInputField("limit", "Лимит", "1000", "number", 12)}

        {/* Кнопка отправки формы */}
        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Добавить
        </button>
      </form>
    </div>
  );
};

export default AddCurrencyModal;
