import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useCreateAdmin,
  useCreateReferrer,
  useDeleteUser,
  useGetUser,
  useGetUsers,
  useUpdateUser,
} from "../../services/user.services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";

import NewAdminModal from "./NewAdminModal";
import NewReferrerModal from "./NewReferrerModal";
import Modal from "../../components/Modal";
import UserFilter from "./UserFilter";
import UserTable from "./UserTable";
import Loading from "../../components/Loading";
import { useSetFees } from "../../services/fees.services";
import UpdateUserFeeModal from "./UpdateUserFeeModal";
import EmptyList from "../../components/EmptyList";

// Схемы валидации
const NewAdminFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Неверный формат электронной почты!")
    .required("Почта обязательное поле!"),
  password: yup
    .string()
    .required("Пароль является обязательным полем!")
    .min(6, "Пароль должен содержать минимум 6 символов"),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Пароли должны совпадать!")
    .required("Повторите пароль"),
});

const UpdateComissionFormSchema = yup.object().shape({
  fee: yup
    .number()
    .typeError("Введите корректное значение комиссии")
    .min(0, "Комиссия не может быть меньше 0")
    .required("Комиссия обязательна"),
  fromDate: yup.string().when("isDeferred", {
    is: (isDeferred: boolean) => isDeferred === true,
    then: (schema) =>
      schema.required("Дата обязательна для отложенного запуска"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const NewReferrerFormSchema = yup.object().shape({
  tgUserName: yup
    .string()
    .required("Telegram ID пользователя является обязательным полем!"),
});

const Users: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [isNewAdminModalOpen, setIsNewAdminModalOpen] = useState(false);
  const [isNewReferrerModalOpen, setIsNewReferrerModalOpen] = useState(false);
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Получение фильтра из URL
  const getFilterFromUrl = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const roleId = searchParams.get("roleId")
      ? Number(searchParams.get("roleId"))
      : 3;
    return { roles: [{ id: roleId }] };
  }, [location.search]);

  const [filter, setFilter] = useState(getFilterFromUrl);

  // Запросы данных
  const { user, refetch: refetchUser } = useGetUser(selectedUserId!);
  const {
    users,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useGetUsers(undefined, undefined, undefined, JSON.stringify(filter));

  // Мутации
  const { mutate: createAdmin } = useCreateAdmin(
    () => {
      refetch();
      toast.success("Администратор успешно добавлен!");
      resetNewAdminForm();
      setIsNewAdminModalOpen(false);
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: deleteUser } = useDeleteUser(
    () => {
      refetch();
      toast.success("Пользователь успешно удален!");
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: updateUser } = useUpdateUser(
    () => {
      refetch();
      toast.success("Пользователь успешно обновлен!");
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: createReferrer } = useCreateReferrer(
    () => {
      refetch();
      toast.success("Реферал успешно создан!");
      setIsNewReferrerModalOpen(false);
      resetCreateReferer();
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: setFee } = useSetFees(
    () => {
      setIsUpdateUserModalOpen(false);
      toast.success("Комиссия успешно изменена!");
      refetch();
    },
    () => toast.error("Не удалось изменить комиссию.")
  );

  // Рефы для пагинации
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  // Формы
  const newAdminReturnForm = useForm({
    resolver: yupResolver(NewAdminFormSchema),
  });
  const updateComissionReturnForm = useForm({
    resolver: yupResolver(UpdateComissionFormSchema),
  });
  const newReferrerReturnForm = useForm({
    resolver: yupResolver(NewReferrerFormSchema),
  });

  const { reset: resetNewAdminForm } = newAdminReturnForm;
  const { reset: resetCreateReferer } = newReferrerReturnForm;

  // Обработчики форм
  const handleSubmitNewAdminForm = (data: {
    email: string;
    password: string;
  }) => {
    createAdmin({ email: data.email, password: data.password });
  };

  const handleSubmitNewReferrerForm = (data: { tgUserName: string }) => {
    createReferrer(data);
  };

  const handleDeleteUser = (id: string) => deleteUser(id);
  const handleBlockUser = (id: string) =>
    updateUser({ user: { status: { id: 2 } }, id });
  const handleUnblockUser = (id: string) =>
    updateUser({ user: { status: { id: 1 } }, id });

  const handleChangeUser = (id: number) => {
    setSelectedUserId(id);
    setIsUpdateUserModalOpen(true);
  };

  const handleUpdateUserComissionForm = (data: {
    fee: number;
    fromDate?: string;
  }) => {
    if (!user) return;

    setFee(
      data.fromDate
        ? {
            userId: user.id,
            userType: user.role.id,
            fee: data.fee,
            fromDate: data.fromDate,
          }
        : { userId: user.id, userType: user.role.id, fee: data.fee }
    );
  };

  // Пагинация
  const observePagination = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading || !hasNextPage || isFetchingNextPage || !node) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      observerRef.current.observe(node);
    },
    [isLoading, hasNextPage, isFetchingNextPage, fetchNextPage]
  );

  useEffect(() => {
    if (loadMoreRef.current) observePagination(loadMoreRef.current);
  }, [observePagination]);

  // Обновление фильтра при изменении URL
  useEffect(() => {
    const currentFilter = getFilterFromUrl();
    setFilter(currentFilter);
    refetch();
  }, [location.search, getFilterFromUrl, refetch]);

  // Обновление данных пользователя при изменении selectedUserId
  useEffect(() => {
    if (selectedUserId) refetchUser();
  }, [selectedUserId, refetchUser]);

  // Обработчик изменения фильтра
  const handleFilterChange = (newFilter: { roles: { id: number }[] }) => {
    const searchParams = new URLSearchParams();
    searchParams.set("roleId", newFilter.roles[0].id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <main className="main users">
      {isLoading && <Loading />}
      <h1 className="users__title title">Пользователи</h1>

      <UserFilter
        filter={filter}
        setFilter={handleFilterChange}
        toggleNewAdminModal={() => setIsNewAdminModalOpen(true)}
        toggleNewReferrerModal={() => setIsNewReferrerModalOpen(true)}
      />

      {!isLoading && users.length > 0 ? (
        <UserTable
          users={users}
          handleDeleteUser={handleDeleteUser}
          handleBlockUser={handleBlockUser}
          handleUnblockUser={handleUnblockUser}
          handleChangeUser={handleChangeUser}
        />
      ) : (
        !isLoading && (
          <EmptyList text="Пользователи с данной ролью отсутствуют." />
        )
      )}

      <div ref={loadMoreRef}></div>

      {/* Модальные окна */}
      <Modal
        isOpen={isNewAdminModalOpen}
        onClose={() => setIsNewAdminModalOpen(false)}
        title="Создать нового админа"
      >
        <NewAdminModal
          returnFormInstance={newAdminReturnForm}
          onSubmit={handleSubmitNewAdminForm}
        />
      </Modal>

      <Modal
        isOpen={isNewReferrerModalOpen}
        onClose={() => setIsNewReferrerModalOpen(false)}
        title="Создать нового реферрера"
      >
        <NewReferrerModal
          returnFormInstance={newReferrerReturnForm}
          onSubmit={handleSubmitNewReferrerForm}
        />
      </Modal>

      <Modal
        isOpen={isUpdateUserModalOpen}
        onClose={() => setIsUpdateUserModalOpen(false)}
        title={
          user?.role?.id === 2
            ? `Изменить начисление реферера ${user?.userName || ""} ID ${
                user?.id
              }`
            : `Изменить начисление трейдера ${user?.userName || ""} ID ${
                user?.id
              }`
        }
      >
        <UpdateUserFeeModal
          returnFormInstance={updateComissionReturnForm}
          onSubmit={handleUpdateUserComissionForm}
          user={user}
        />
      </Modal>
    </main>
  );
};

export default Users;
