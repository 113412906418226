export const ROUTES = {
  MAIN: "/",
  USERS: "/users",
  EXCHANGE: "/exchange",
  HISTORY: "/history",
  CHATS: "/chats",
  FEES: "/fees",
  ADDRESSES: "/addresses",
  LOGIN: "/login",
  REGISTRATION: "/registration",
  CITIES: "/cities",
  COUNTRIES: "/countries",
  CURRENCIES: "/currencies",
  LANGUAGES: "/languages",
  DEPOSITS: "/deposits",
  WITHDRAWALS: "/withdrawals",
};
