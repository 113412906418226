import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../components/Modal";
import NewAddressModal from "./NewAddressModal";
import toast from "react-hot-toast";
import {
  useAddAddress,
  useDeleteAddress,
  useGetAddresses,
} from "../../services/addresses.services";

// Схема валидации для формы добавления адреса
const NewAddressFormSchema = yup.object().shape({
  cityId: yup.number().required("Выберите город"),
  countryId: yup.number().required("Выберите страну"),
  street: yup.string().required("Введите адрес"),
  currencyId: yup.number().required("Выберите валюту"),
});

const Addresses: React.FC = () => {
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] =
    useState<boolean>(false);

  const {
    addresses,
    refetch,
    isLoading: isAddressesLoading,
    isError: isAddressesError,
  } = useGetAddresses();

  const useFormReturn = useForm({
    resolver: yupResolver(NewAddressFormSchema),
  });

  const { reset } = useFormReturn;

  // Обработчик успешного добавления адреса
  const onAddAddressSuccess = () => {
    toast.success("Адрес выдачи средств успешно добавлен!");
    refetch();
    setIsNewAddressModalOpen(false);
    reset(); // Сброс формы
  };

  // Обработчик ошибки при добавлении адреса
  const onAddAddressError = () => {
    toast.error("Ошибка при добавлении адреса! Попробуйте позже.");
  };

  // Обработчик успешного удаления адреса
  const onDeleteAddressSuccess = () => {
    toast.success("Пункт выдачи успешно удален!");
    refetch();
  };

  // Обработчик ошибки при удалении адреса
  const onDeleteAddressError = () => {
    toast.error("Ошибка при удалении адреса! Попробуйте позже.");
  };

  const { mutate: addAddress } = useAddAddress(
    onAddAddressSuccess,
    onAddAddressError
  );
  const { mutate: deleteAddress } = useDeleteAddress(
    onDeleteAddressSuccess,
    onDeleteAddressError
  );

  // Обработчик отправки формы добавления адреса
  const handleSubmitNewAddressForm = (data: {
    cityId: number;
    street: string;
    countryId: number;
    currencyId: number;
  }) => {
    try {
      addAddress(data);
    } catch (error) {
      console.error("Ошибка при отправке формы: ", error);
    }
  };

  // Обработчик удаления адреса
  const handleDeleteAddress = (id: number) => {
    try {
      deleteAddress(id);
    } catch (error) {
      console.error("Ошибка при удалении адреса: ", error);
    }
  };

  // Обработчик открытия/закрытия модального окна
  const toggleNewAddressModal = () => {
    setIsNewAddressModalOpen((prev) => !prev);
  };

  if (isAddressesLoading) return <p>Загрузка адресов...</p>;
  if (isAddressesError)
    return <p className="error">Ошибка при загрузке адресов!</p>;

  return (
    <main className="main addresses">
      <div className="exchange__header">
        <h1 className="addresses__title title">Адреса выдачи cash</h1>
        <button onClick={toggleNewAddressModal} className="button">
          Добавить новый адрес
        </button>
      </div>

      {/* Таблица с адресами */}
      <div className="addresses__table table-addresses table">
        <div className="table-addresses__items table__items table__items--head">
          <div className="table__item">Страна</div>
          <div className="table__item">Город</div>
          <div className="table__item">Адрес</div>
          <div className="table__item">Валюта</div>
          <div className="table__item"></div>
        </div>
        {addresses?.map((address: any) => (
          <div className="table-addresses__items table__items" key={address.id}>
            <div className="table__item">
              {address.fullAddress.split(", ")[0]}
            </div>
            <div className="table__item">
              {address.fullAddress.split(", ")[1]}
            </div>
            <div className="table__item">
              {address.fullAddress.split(", ").slice(2).join(" ")}
            </div>
            <div className="table__item">{address.currency}</div>
            <div className="table__item">
              <button
                onClick={() => handleDeleteAddress(address.id)}
                className="table__item-img"
              >
                <img
                  className="table__item-icon"
                  src="/img/svg/del.svg"
                  alt="Удалить"
                />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Модальное окно для добавления нового адреса */}
      <Modal
        title="Новый адрес выдачи наличных"
        isOpen={isNewAddressModalOpen}
        onClose={() => setIsNewAddressModalOpen(false)}
      >
        <NewAddressModal
          returnFormInstance={useFormReturn}
          onSubmit={handleSubmitNewAddressForm}
        />
      </Modal>
    </main>
  );
};

export default Addresses;
