import React, { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import {
  useGetCities,
  useGetCountries,
} from "../../services/addresses.services";
import { useGetCurrencyList } from "../../services/currencies.services";

interface IProps {
  returnFormInstance: UseFormReturn<{
    cityId: number;
    countryId: number;
    street: string;
    currencyId: number;
  }>;
  onSubmit: (data: {
    cityId: number;
    countryId: number;
    street: string;
    currencyId: number;
  }) => void;
}

const NewAddressModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
}) => {
  const {
    countries,
    isLoading: isCountriesLoading,
    isError: isCountriesError,
  } = useGetCountries();
  const {
    currencyList,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
  } = useGetCurrencyList();
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    null
  );
  const {
    cities,
    refetch: refetchCities,
    isLoading: isCitiesLoading,
    isError: isCitiesError,
  } = useGetCities(selectedCountryId!);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = returnFormInstance;

  const watchCountryId = watch("countryId");

  // Обновляем список городов при изменении выбранной страны
  useEffect(() => {
    if (watchCountryId) {
      setSelectedCountryId(watchCountryId);
      refetchCities();
    }
  }, [watchCountryId, refetchCities]);

  // Обработка ошибок загрузки данных
  if (isCountriesError || isCurrenciesError || isCitiesError) {
    return (
      <p className="error">
        Ошибка при загрузке данных. Пожалуйста, попробуйте позже.
      </p>
    );
  }

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        {/* Выбор страны */}
        <Controller
          name="countryId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="countryId" className="form__label">
                Страна
              </label>
              <select
                id="countryId"
                className="form-control"
                value={value}
                onChange={onChange}
                disabled={isCountriesLoading}
              >
                <option value="">Выберите страну</option>
                {countries?.map((country: any) => (
                  <option key={country.id} value={country.id}>
                    {country.country}
                  </option>
                ))}
              </select>
              {errors.countryId?.message && (
                <p className="error">{errors.countryId?.message}</p>
              )}
            </div>
          )}
        />

        {/* Выбор города */}
        <Controller
          name="cityId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="cityId" className="form__label">
                Город
              </label>
              <select
                id="cityId"
                className="form-control"
                value={value}
                onChange={onChange}
                disabled={!selectedCountryId || isCitiesLoading}
              >
                <option value="">Выберите город</option>
                {cities?.map((city: any) => (
                  <option key={city.id} value={city.id}>
                    {city.city}
                  </option>
                ))}
              </select>
              {errors.cityId?.message && (
                <p className="error">{errors.cityId?.message}</p>
              )}
            </div>
          )}
        />

        {/* Поле для ввода адреса */}
        <Controller
          name="street"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="street" className="form__label">
                Адрес
              </label>
              <input
                id="street"
                className="form-control"
                type="text"
                placeholder="Введите адрес"
                value={value}
                onChange={onChange}
              />
              {errors.street?.message && (
                <p className="error">{errors.street?.message}</p>
              )}
            </div>
          )}
        />

        {/* Выбор валюты */}
        <Controller
          name="currencyId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="currencyId" className="form__label">
                Валюта
              </label>
              <select
                id="currencyId"
                className="form-control"
                value={value}
                onChange={onChange}
                disabled={isCurrenciesLoading}
              >
                <option value="">Выберите валюту</option>
                {currencyList?.map((currency: any) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.code} ({currency.name})
                  </option>
                ))}
              </select>
              {errors.currencyId?.message && (
                <p className="error">{errors.currencyId?.message}</p>
              )}
            </div>
          )}
        />

        {/* Кнопка отправки формы */}
        <button type="submit" className="create-admin__button button">
          Добавить
        </button>
      </form>
    </div>
  );
};

export default NewAddressModal;
