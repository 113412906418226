import authApi from "../config/authApi.config"; // Ваш сконфигурированный axios instance
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

const getChats = async ({
  pageParam = 1,
  limit = 10,
  ...params
}: {
  pageParam?: number;
  limit?: number;
  keyword?: string;
  isFinished?: boolean;
  isChat?: boolean;
}) => {
  const response = await authApi.get("/chats/supportChatLists", {
    params: {
      page: pageParam,
      limit,
      ...params,
    },
  });
  return response.data;
};

const getMessages = async (ticketId: number, isChat?: boolean) => {
  return authApi
    .get(
      `/chats/byOrderOrTicket/${ticketId}?isChat=${
        isChat || false
      }&page=1&limit=100`
    )
    .then((res) => res.data?.reverse())
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetChats = (params: {
  limit?: number;
  keyword?: string;
  isFinished?: boolean;
  isChat?: boolean;
}) => {
  return useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ["chats", params],
    queryFn: ({ pageParam }) => getChats({ pageParam, ...params }),
    getNextPageParam: (lastPage, allPages) => {
      const hasMorePages = lastPage?.hasMorePages;
      const nextPage = allPages.length + 1;

      return hasMorePages ? nextPage : undefined;
    },
  });
};

export const useGetMessages = (ticketId: number, isChat?: boolean) => {
  const {
    isError,
    isLoading,
    data: messages,
  } = useQuery({
    queryKey: ["Messages", ticketId],
    queryFn: () => getMessages(ticketId, isChat),
  });

  return {
    messages: messages,
    isLoading,
    isError,
  };
};
