import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getWithdrawalList = async (
  page: number,
  limit?: number,
  keyword?: string
) => {
  return authApi
    .get(
      `/payments/findWithDrawData?limit=${limit || 10}&page=${page}${
        keyword ? `&keyword=${keyword}` : ``
      }`
    )
    .then((res) => res.data);
};

const confirmPaymentWithdraw = async (paymentId: number) => {
  return authApi
    .patch("/payments/confirmWithdraw", { paymentId })
    .then((res) => res.data);
};

const rejectPaymentWithdrawal = async (paymentId: number) => {
  return authApi
    .patch("/payments/rejectWithdraw", {
      paymentId,
    })
    .then((res) => res.data);
};

export const useRejectPaymentWithdrawal = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["RejectWithdraw"],
    mutationFn: (paymentId: number) => rejectPaymentWithdrawal(paymentId),
    onSuccess,
    onError,
  });
};

export const useConfirmPaymentWithdrawal = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["ConfirmWithdraw"],
    mutationFn: (paymentId: number) => confirmPaymentWithdraw(paymentId),
    onSuccess,
    onError,
  });
};

export const useGetWithdrawalList = (data: {
  page: number;
  limit?: number;
  keyword?: string;
}) => {
  const {
    isLoading,
    isError,
    data: withdrawalList,
    refetch,
  } = useQuery({
    queryKey: ["WithdrawalList"],
    queryFn: () => getWithdrawalList(data.page, data.limit, data.keyword),
  });

  return {
    isLoading,
    isError,
    withdrawalList,
    refetch,
  };
};
