import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useGetCountries } from "../../services/addresses.services";

interface IProps {
  returnFormInstance: UseFormReturn<{ countryId: number; city: string }>;
  onSubmit: (data: { countryId: number; city: string }) => void;
}

const NewCityModal: React.FC<IProps> = ({ returnFormInstance, onSubmit }) => {
  const {
    countries,
    isLoading: isCountriesLoading,
    isError: isCountriesError,
  } = useGetCountries();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  // Обработчик ошибок загрузки стран
  if (isCountriesError) {
    return <p className="error">Ошибка при загрузке списка стран!</p>;
  }

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        {/* Поле выбора страны */}
        <Controller
          name="countryId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="countryId" className="form__label">
                Страна
              </label>
              <select
                id="countryId"
                className="form-control"
                value={value}
                onChange={onChange}
                disabled={isCountriesLoading} // Блокируем поле, если страны загружаются
              >
                <option value="">Выберите страну</option>
                {countries?.map((country: any) => (
                  <option key={country.id} value={country.id}>
                    {country.country}
                  </option>
                ))}
              </select>
              {errors.countryId?.message && (
                <p className="error">{errors.countryId?.message}</p>
              )}
            </div>
          )}
        />

        {/* Поле ввода названия города */}
        <Controller
          name="city"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="city" className="form__label">
                Название города
              </label>
              <input
                id="city"
                className="form-control"
                type="text"
                placeholder="Введите название города"
                value={value}
                onChange={onChange}
              />
              {errors.city?.message && (
                <p className="error">{errors.city?.message}</p>
              )}
            </div>
          )}
        />

        {/* Кнопка добавления */}
        <button type="submit" className="create-admin__button button">
          Добавить
        </button>
      </form>
    </div>
  );
};

export default NewCityModal;
