import React, { useState } from "react";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import {
  useAddCountry,
  useDeleteCountry,
  useGetCountries,
} from "../../services/addresses.services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import NewCountryModal from "./NewCountryModal";
import CountriesList from "./CountriesList";
import { NewCountryFormSchema } from "./schemas";

const Countries: React.FC = () => {
  const [isNewCountryModalOpen, setIsNewCountryModalOpen] =
    useState<boolean>(false);

  const {
    countries,
    refetch,
    isLoading,
    isError: isCountriesError,
  } = useGetCountries();

  const useNewCountryForm = useForm<{ country: string }>({
    resolver: yupResolver(NewCountryFormSchema),
  });

  const { reset } = useNewCountryForm;

  const { mutate: addCountry } = useAddCountry(
    () => {
      setIsNewCountryModalOpen(false);
      toast.success("Страна успешно добавлена!");
      reset();
      refetch();
    },
    () => {
      toast.error("Ошибка при добавлении страны!");
    }
  );

  const { mutate: deleteCountry } = useDeleteCountry(
    () => {
      refetch();
      toast.success("Страна успешно удалена!");
    },
    () => {
      toast.error("Ошибка при удалении страны!");
    }
  );

  const handleSubmitNewCountryForm = (data: { country: string }) => {
    try {
      addCountry(data.country);
    } catch (error) {
      toast.error("Произошла ошибка при отправке формы!");
    }
  };

  const handleDeleteCountry = (id: string) => {
    try {
      deleteCountry(id);
    } catch (error) {
      toast.error("Произошла ошибка при удалении страны!");
    }
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      {isCountriesError && (
        <p className="error">Ошибка при загрузке списка стран!</p>
      )}

      <div className="exchange__header">
        <h1 className="exchange__title title">Доступные страны</h1>
        <button
          onClick={() => setIsNewCountryModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить новую страну
        </button>
      </div>

      {countries && (
        <CountriesList countries={countries} onDelete={handleDeleteCountry} />
      )}

      <Modal
        title="Новая страна"
        isOpen={isNewCountryModalOpen}
        onClose={() => setIsNewCountryModalOpen(false)}
      >
        <NewCountryModal
          returnFormInstance={useNewCountryForm}
          onSubmit={handleSubmitNewCountryForm}
        />
      </Modal>
    </main>
  );
};

export default Countries;
