import React, { useState } from "react";
import * as yup from "yup";
import {
  useAddCurrencyPair,
  useDeleteCurrencyPair,
  useGetCurrencyPairs,
  useUpdateCurrencyPair,
} from "../../services/currency-pairs.services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IAddCurrencyPairRequestData } from "../../models/currency-pairs.models";
import Modal from "../../components/Modal";
import NewCurrencyPairModal from "./NewCurrencyPairModal";
import Loading from "../../components/Loading";
import UpdateCurrencyPairModal from "./UpdateCurrencyPairModal";

// Схемы валидации
const UpdateCurrencyPairSchema = yup.object().shape({
  exchangeRate: yup.number().required("Укажите новый курс выбранной пары!"),
  profit: yup.number().required("Укажите профит от данного курса!"),
});

const NewCurrencyPairSchema = yup.object().shape({
  baseCurrencyId: yup.number().required("Выберите первую валюту!"),
  targetCurrencyId: yup.number().required("Выберите вторую валюту!"),
  exchangeRate: yup.number().required("Укажите курс выбранных валют!"),
  profit: yup.number().required("Профит курса является обязательным полем!"),
});

const Exchange: React.FC = () => {
  const [isNewCurrencyPairModalOpen, setIsNewCurrencyPairModalOpen] =
    useState(false);
  const [isUpdateCurrencyPairModalOpen, setIsUpdateCurrencyPairModalOpen] =
    useState(false);
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState<{
    id: number;
    exchangeRate: number;
    profit: number;
  } | null>(null);

  const { currencyPairs, refetch, isLoading } = useGetCurrencyPairs();

  // Мутации
  const { mutate: deleteCurrencyPair } = useDeleteCurrencyPair(
    () => {
      refetch();
      toast.success("Пара валют успешно удалена!");
    },
    () => {
      toast.error("Не удалось удалить пару валют. Попробуйте позже.");
    }
  );

  const { mutate: addCurrencyPair } = useAddCurrencyPair(
    () => {
      toast.success("Пара успешно создана!");
      setIsNewCurrencyPairModalOpen(false);
      resetNewCurrencyPairForm();
      refetch();
    },
    () => {
      toast.error("Не удалось создать пару валют. Попробуйте позже.");
    }
  );

  const { mutate: updateCurrencyPair } = useUpdateCurrencyPair(
    () => {
      toast.success("Пара успешно обновлена!");
      setIsUpdateCurrencyPairModalOpen(false);
      refetch();
    },
    () => {
      toast.error("Не удалось обновить пару валют. Попробуйте позже.");
    }
  );

  // Формы
  const useNewCurrencyPairReturnForm = useForm({
    resolver: yupResolver(NewCurrencyPairSchema),
  });

  const useUpdateCurrencyPairReturnForm = useForm({
    resolver: yupResolver(UpdateCurrencyPairSchema),
  });

  const { reset: resetNewCurrencyPairForm } = useNewCurrencyPairReturnForm;

  // Обработчики форм
  const handleSubmitNewCurrencyPairForm = (
    data: IAddCurrencyPairRequestData
  ) => {
    addCurrencyPair(data);
  };

  const handleSubmitUpdateCurrencyPairForm = (data: {
    exchangeRate: number;
    profit: number;
  }) => {
    if (selectedCurrencyPair) {
      updateCurrencyPair({ ...data, id: selectedCurrencyPair.id });
    }
  };

  const handleUpdateCurrencyPair = (currencyPair: {
    id: number;
    exchangeRate: number;
    profit: number;
  }) => {
    setSelectedCurrencyPair(currencyPair);
    setIsUpdateCurrencyPairModalOpen(true);
  };

  // Обработчик удаления пары валют
  const handleDeleteCurrencyPair = (id: number) => {
    if (window.confirm("Вы уверены, что хотите удалить эту пару валют?")) {
      deleteCurrencyPair(id.toString());
    }
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      <div className="exchange__header">
        <h1 className="exchange__title title">Управление курсами</h1>
        <button
          onClick={() => setIsNewCurrencyPairModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить новую пару
        </button>
      </div>
      {/* Таблица с парами валют */}
      <div className="exchange__table table-exchange table">
        <div className="table-exchange__items table__items table__items--head">
          <div className="table__item">Из</div>
          <div className="table__item"></div>
          <div className="table__item">В</div>
          <div className="table__item">Курс обмена</div>
          <div className="table__item">Профит, %</div>
          <div className="table__item"></div>
        </div>
        {currencyPairs?.map((currencyPair) => (
          <div
            key={currencyPair.id}
            className="table-exchange__items table__items"
          >
            <div className="table__item">
              1 {currencyPair.baseCurrencyId?.code}
            </div>
            <div className="table__item">
              <img
                className="table__item-icon"
                src="/img/svg/swap.svg"
                alt="swap-icon"
              />
            </div>
            <div className="table__item">
              {currencyPair.targetCurrencyId?.code}
            </div>
            <div className="table__item">{currencyPair.exchangeRate}</div>
            <div className="table__item">{currencyPair.profit}</div>
            <div className="table__item">
              <div className="table__item-action">
                <button
                  onClick={() => handleUpdateCurrencyPair(currencyPair)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/edit.svg"
                    alt="Редактировать"
                  />
                </button>
                <button
                  onClick={() => handleDeleteCurrencyPair(currencyPair.id)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/del.svg"
                    alt="Удалить"
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Модальные окна */}
      <Modal
        title="Новая пара валют"
        isOpen={isNewCurrencyPairModalOpen}
        onClose={() => setIsNewCurrencyPairModalOpen(false)}
      >
        <NewCurrencyPairModal
          returnFormInstance={useNewCurrencyPairReturnForm}
          onSubmit={handleSubmitNewCurrencyPairForm}
        />
      </Modal>
      <Modal
        title="Изменение пары"
        isOpen={isUpdateCurrencyPairModalOpen}
        onClose={() => setIsUpdateCurrencyPairModalOpen(false)}
      >
        <UpdateCurrencyPairModal
          returnFormInstance={useUpdateCurrencyPairReturnForm}
          onSubmit={handleSubmitUpdateCurrencyPairForm}
          initialValues={selectedCurrencyPair!} // Передаем начальные значения
        />
      </Modal>
      ;
    </main>
  );
};

export default Exchange;
