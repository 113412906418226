import React, { useEffect, useState, useCallback } from "react";
import { useGetMe } from "../../services/user.services";
import { io } from "socket.io-client";
import { useGetMessages } from "../../services/chat.services";

interface IProps {
  ticketId?: number;
}

interface Message {
  id: number;
  text: string;
  sender: "user" | "support";
  time: string;
  date: string;
  read?: boolean;
}

const SOCKET_URL = "https://bobster.freeblock.site/websocket";

const ChatModal: React.FC<IProps> = ({ ticketId }) => {
  const [socket, setSocket] = useState<any>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");

  const { me } = useGetMe();
  const { messages: messageList } = useGetMessages(ticketId!);
  const token = localStorage.getItem("token");

  // Загрузка сообщений при изменении messageList или me
  useEffect(() => {
    if (messageList && messageList.length > 0 && me) {
      const loadedMessages = messageList.map((msg: any) => ({
        id: msg.chatMessage_createdAt,
        text: msg.chatMessage_message,
        sender: msg.chatMessage_senderId === me?.id ? "user" : "support",
        time: new Date(msg.chatMessage_createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: msg.chatMessage_createdAt.split("T")[0],
        read: msg.chatMessage_read,
      }));
      setMessages(loadedMessages);
    }
  }, [messageList, me]);

  // Подключение к WebSocket
  useEffect(() => {
    const newSocket = io(SOCKET_URL, {
      query: { token: token },
      transports: ["websocket"],
    });

    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    newSocket.on("message", (message) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: message.id,
          text: message.message,
          sender: message.senderId === me?.id ? "user" : "support",
          time: new Date(message.createdAt).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: message.createdAt.split("T")[0],
          read: message.read,
        },
      ]);
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    return () => {
      newSocket.close();
    };
  }, [token, me]);

  // Отправка сообщения
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (inputValue.trim() && socket && me && messageList.length > 0) {
        const newMessage: Message = {
          id: Date.now(),
          text: inputValue,
          sender: "user",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: new Date().toISOString().split("T")[0],
          read: false,
        };

        socket.emit("sendMessage", {
          senderId: me.id,
          receiverId: messageList[0].chatMessage_senderId,
          ticketId: ticketId,
          message: inputValue,
          orderId: "support",
        });

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setInputValue("");
      }
    },
    [inputValue, socket, me, messageList, ticketId]
  );

  // Отправка сообщения по нажатию на Enter
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit]
  );

  // Группировка сообщений по дате
  const groupedMessages = messages.reduce<{ [date: string]: Message[] }>(
    (groups, message) => {
      const date = message.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    },
    {}
  );

  return (
    <div className="create-admin">
      <div className="main chat">
        <div className="body">
          <div className="chat__items">
            {Object.keys(groupedMessages).map((date) => (
              <div key={date}>
                <div className="chat__date">{date}</div>

                {groupedMessages[date].map((message) => (
                  <div key={message.id} className="chat__messages">
                    <div
                      className={`chat__message chat__message--${message.sender}`}
                    >
                      <div className="chat__message-text">{message.text}</div>
                      <div className="chat__message-time">
                        {message.time}
                        {message.sender === "user" && (
                          <div className="chat__message-check">
                            <img
                              className={`chat__message-check-icon ${
                                message.read ? "read" : ""
                              }`}
                              src="/img/svg/check-mes.svg"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <form className="chat__input-container" onSubmit={handleSubmit}>
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--emoji"
              src="/img/svg/smile.svg"
              alt=""
            />
          </button>
          <textarea
            className="chat__input"
            placeholder="Start typing..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            rows={1}
          />
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--mention"
              src="/img/svg/mention.svg"
              alt=""
            />
          </button>
          <button type="submit" className="chat__input-btn">
            <svg className="chat__input-icon chat__input-icon--send">
              <use xlinkHref="/img/sprite.svg#send"></use>
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatModal;
